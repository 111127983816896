import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 24px;
  max-width: ${p => p.maxWidth || 800}px;
  width: 100%;

  @media only screen and (max-width: 648px) {
    padding: 0;
  }
`;
