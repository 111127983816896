module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"pages":"/opt/build/repo/src/templates/Page/index.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#FFF","theme_color":"#663399","display":"minimal-ui","icon":"src/assets/90logo.PNG"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
