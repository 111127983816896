import React from 'react';

import { Container } from '../ui/Container';
import { StyledFooter, Inner, Left, Right, Copyright } from './styled';

export const SocialLink = {
	instagram: 'https://instagram.com/90reasonz',
	youtube: 'https://www.youtube.com/channel/UCOdTLlYcZSFox-6n2yoi1qg',
	soundcloud: 'https://soundcloud.com/90reasonz'
}

const Footer = () => (
  <StyledFooter>
    <Container maxWidth={1200}>
      <Inner>
        <Left>
          <div>
            <a href={SocialLink.instagram}>
							<img src="https://img.icons8.com/color/48/000000/instagram-new.png"></img>
						</a>
          </div>
          <div>
						<div>
							<a href={SocialLink.youtube}>
								<img src="https://img.icons8.com/color/48/000000/youtube-play.png"></img>
							</a>
          	</div>
          </div>
        </Left>
        <Right>
          <div>
            <a href={SocialLink.soundcloud}>
							<img src="https://img.icons8.com/color/48/000000/soundcloud.png"></img>
						</a>
          </div>
        </Right>
      </Inner>
			<Copyright>
				Copyright ©{' '}
				<a href="https://90reasonz.com" target="_blank" rel="noopener noreferrer">
					90reasonz
				</a>
				, 2020.
			</Copyright>
    </Container>
  </StyledFooter>
);

export { Footer };
